import { request } from "@/utils/request";
import { BASE_URL } from "@/constants/config";

export const CHAT_ENDPOINTS = {
  chats: (id) => `${BASE_URL}api/chats/${id}`,
};

class Chat {
  async create(body, id) {
    var { data, res } = await request({
      url: CHAT_ENDPOINTS.chats(id),
      method: "POST",
      body,
    });
    return {
      data,
      res,
    };
  }

  async getAll(id) {
    var { data, res } = await request({
      url: CHAT_ENDPOINTS.chats(id),
      method: "GET",
    });
    return {
      data,
      res,
    };
  }
}

export const ChatService = new Chat();
