<template>
  <div>
    <div
      v-if="isLoadingReportData"
      class="d-flex align-items-center justify-content-center mt-5"
    >
      <CSpinner
        color="primary"
        style="color: #274b8e; width: 50px; height: 50px; margin-top: 100px"
      />
    </div>
    <div v-else>
      <b-form-group
        id="input-group-1"
        label="Name"
        label-for="input-1"
        description=""
      >
        <b-form-input
          id="input-1"
          v-model="report.name"
          type="text"
          placeholder="Enter name"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-2" label="Description" label-for="input-2">
        <b-form-textarea
          id="input-2"
          v-model="report.description"
          placeholder="Enter description"
          rows="4"
          formatter
          max-rows="4"
        ></b-form-textarea>
      </b-form-group>
      <b-form-group label="Status" id="input-group-5" label-for="input-5">
        <v-select
          placeholder="Status"
          :options="statusOptions"
          v-model="report.status"
          :clearable="false"
          class="add-tag"
          style="text-transform: capitalize"
        />
      </b-form-group>

      <b-form-group id="input-group-3" label="Tags" label-for="input-3">
        <b-form-tags input-id="tags-basic" v-model="report.tags"></b-form-tags>
      </b-form-group>

      <b-form-group
        label="Table"
        id="input-group-4"
        label-for="input-4"
        style="position: relative"
      >
        <img
          src="/img/copy.svg"
          alt="copy"
          style="
            position: absolute;
            z-index: 555;
            top: 0;
            left: 35px;
            cursor: pointer;
          "
          @click="onCopy(true)"
        />
        <v-select
          v-if="!isEdit"
          placeholder="Select DataMorph table"
          :options="reportTables"
          @input="onTemplateChange"
          v-model="tableId"
          class="add-tag"
          :clearable="false"
          :reduce="(temp) => temp.id"
          label="table_name"
        >
          <!-- <li slot="list-footer">
            <b-button
              class="btn ml-2 mt-2 small cus-button"
              @click="loadMore"
              :disabled="disableLoadMore"
            >
              <div
                class="btn-loader__wrapper"
                :class="{ 'one-col': !isLoadingData }"
              >
                <span>Load more</span>
                <CSpinner
                  v-if="isLoadingData"
                  color="white"
                  style="width: 1rem; height: 1rem"
                />
              </div>
            </b-button>
          </li> -->
        </v-select>
        <v-select
          disabled
          v-else
          placeholder="Select DataMorph table"
          :options="[{ ...report.table_data.id }]"
          :value="report.table_data && report.table_data.table_name"
          class="add-tag"
          :reduce="(temp) => temp.id"
          :clearable="false"
          label="table_name"
          @input="onTableChange"
        />
      </b-form-group>

      <div>
        <ul v-if="!isColumnsLoading">
          <li v-for="t in tableColumns" :key="t.name">
            {{ t.name }} ({{ t.type }})
          </li>
        </ul>
        <CSpinner v-else color="black" style="width: 1rem; height: 1rem" />
      </div>

      <b-form-group
        label="PBIT file"
        label-for="input-7"
        style="margin-top: 20px; position: relative"
      >
        <img
          src="/img/dwonload.svg"
          alt="download"
          style="
            position: absolute;
            z-index: 555;
            top: 2px;
            left: 50px;
            cursor: pointer;
          "
          @click="onDownload"
        />
        <div v-if="isEdit">
          {{ report.power_bi_file_name }}
        </div>
        <b-form-file
          id="input-7"
          v-model="report.file"
          :state="Boolean(report.file || report.power_bi_file_name)"
          placeholder="Choose a file or drop it here"
          drop-placeholder="Drop file here"
          accept=".pbit"
        ></b-form-file>
        <b-form-invalid-feedback :state="true">
          Please upload a PBIT file, which has a DataMorph table as a data
          source
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Image" label-for="input-5">
        <b-form-file
          id="input-5"
          v-model="report.image"
          :state="Boolean(report.image || report.imageSrc)"
          placeholder="Choose an image or drop it here"
          drop-placeholder="Drop image here"
          accept="image/*"
          @change="handleImageChange"
        ></b-form-file>
      </b-form-group>
      <div style="margin-left: 10px" v-if="report.imageSrc">
        <img :src="report.imageSrc" height="130px" />
      </div>
      <br />
      <b-form-group
        v-if="isBiAdmin"
        label="URL"
        label-for="input-6"
        description=""
      >
        <b-form-input
          id="input-6"
          v-model="report.url"
          type="text"
          placeholder="Enter URL"
          aria-describedby="input-live-help input-live-feedback"
          required
          :state="checkUrl"
        ></b-form-input>
        <b-form-invalid-feedback id="input-live-feedback">
          Invalid URL
        </b-form-invalid-feedback>
      </b-form-group>
    </div>
  </div>
</template>
<script>
import { FilesService } from "@/services/files";
import { ReportService } from "@/services/reports";
import { TemplatesService } from "@/services/templates";
import { ActiveUser } from "@/services/user";
import { fileToBase64 } from "@/utils/helpers";
import { statuses, statusesAccessibleForClient } from "@/constants/report";
import { AnonymizeService } from "@/services/anonymize";

export default {
  name: "ReportCreate",
  props: {
    data: Object,
  },
  data() {
    return {
      report: { tags: [] },
      reportTables: [],
      columnTypes: ["Numeric", "String", "Date", "Boolean", "Country", "City"],
      isLoading: false,
      statuses,
      tableId: null,
      isLoadingData: true,
      isLoadingReportData: false,
      total: 0,
      page: 1,
      page_size: 100000,
      tableColumns: [],
      isColumnsLoading: false,
    };
  },
  computed: {
    checkUrl() {
      if (!this.isAdmin) return true;
      return /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/.test(
        this.report.url
      );
    },
    isAdmin() {
      return ActiveUser.isBiAdmin();
    },
    statusOptions() {
      return this.isAdmin ? this.statuses : statusesAccessibleForClient;
    },
    currentTable() {
      return this.reportTables.find((i) => i.id === this.tableId);
    },
    isBiAdmin() {
      return this.$store.state.role.includes("bi_admin");
      // return ActiveUser.isBiAdmin();
    },
    isEdit() {
      return !!this.data;
    },
    disableLoadMore() {
      return (
        Math.ceil(this.total / this.page_size) <= this.page ||
        this.isLoadingData
      );
    },
  },
  watch: {
    data: {
      immediate: true,
      handler() {
        if (!!this.data) {
          this.isLoadingReportData = true;
          this.getData();
        }
      },
    },
    report(newReport) {
      this.$emit("report-updated", {
        report: newReport,
        tableId: this.tableId,
      });
    },
  },
  mounted() {
    if (!this.isEdit) this.getColumns();
  },
  methods: {
    loadMore() {
      this.page = this.page + 1;
      this.getColumns();
    },
    onTemplateChange(e) {
      this.templateId = e;
      this.$emit("report-updated", {
        report: this.report,
        tableId: e,
      });
      this.isColumnsLoading = true;

      TemplatesService.parseColumnsDetail(this.templateId)
        .then(({ data }) => {
          const temp = data.data;
          this.tableColumns = temp.columns;
        })
        .catch((err) => {
          console.log(err);
          alert(err, { type: "error", position: "bottom-right" });
        })
        .finally(() => (this.isColumnsLoading = false));
    },
    onTableChange(selectedTable) {
      const tableData = this.reportTables.find(
        (table) => table.id === selectedTable
      );
      if (tableData) {
        this.report.table_data = {
          id: selectedTable,
          table_name: tableData.table_name,
          ...tableData,
        };
      }
    },
    handleImageChange(event) {
      const selectedFile = event.target.files[0];

      if (selectedFile) {
        // Use FileReader to read the selected image and update report.imageSrc
        const reader = new FileReader();
        reader.onload = (e) => {
          this.report.imageSrc = e.target.result; // Update imageSrc with the base64 data of the image
        };
        reader.readAsDataURL(selectedFile);
      }
    },
    async onCopy(val = false) {
      const t = this.isEdit
        ? this.report.table_data?.table_name
        : this.currentTable.table_name;
      if (!t) {
        return this.$toasted.error("Table not selected", {
          position: "bottom-right",
          duration: 5000,
        });
      }
      try {
        const tableName = t.split("-");
        const { data } = await AnonymizeService.copy(
          val,
          tableName[0],
          tableName[1]
        );
        await navigator.clipboard.writeText(data.conn);
        this.$toasted.info("Connection string copied!", {
          position: "top-right",
          duration: 5000,
        });
      } catch (e) {
        console.log(e);
      }
    },
    onDownload() {
      this.isDownloading = true;
      FilesService.downloadFile(this.report.power_bi_file_id)
        .then((res) => res.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          // the filename you want
          a.download = this.report.power_bi_file_name;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((err) => console.log(err))
        .finally(() => {
          this.isDownloading = false;
        });
    },
    getData() {
      this.isLoadingReportData = true;
      console.log("here");
      ReportService.getById(this.data.id)
        .then(({ data }) => {
          this.report = {
            ...data.data,
            imageSrc: data.data.image,
            image: null,
          };
          this.tableId = this.report.table_data.id;
          this.isLoadingReportData = false;
        })
        .catch((err) => {
          this.isLoadingReportData = false;
          console.log(err);
        });
    },
    getColumns() {
      this.isLoadingData = true;
      TemplatesService.parseColumnsV2({
        page: this.page,
        limit: this.page_size,
      })
        .then(({ data }) => {
          this.isLoadingData = false;
          this.reportTables = [...this.reportTables, ...data.data];
          this.total = data.total;
        })
        .catch((err) => {
          this.isLoadingData = false;
          console.log(err);
        });
    },
    onCheckSource() {
      const fd = new FormData();
      fd.append("file", this.report.file);
      FilesService.uploadFile(fd)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => console.log(err));
    },
    async onSave() {
      try {
        // this.$emit("loadingTrue");
        console.log(this.report);
        this.$emit("isLoad", true);
        if (this.report.file) {
          const fd = new FormData();
          fd.append("file", this.report.file);
          const pbiFile = await FilesService.uploadFile(fd);
          delete this.report.file;
          this.report.power_bi_file_id = pbiFile.id;
          this.report.power_bi_file_name = pbiFile.filename;
        }
        let image;
        if (this.report.image) image = await fileToBase64(this.report.image);
        this.report.author = await ActiveUser.get()?.email;
        // if (this.currentTable) this.report.table_data = this.currentTable;
        if (!!this.isEdit) {
          console.log("table", this.currentTable);
          const { data } = await ReportService.update(
            {
              ...this.report,
              image,
            },
            this.report._id
          );
          if (data.message !== "ok") {
            throw new Error(data.message);
          }
        } else {
          const res = await ReportService.create({
            ...this.report,
            image,
            table_data: this.currentTable,
          });
          if (res.data.message !== "ok") {
            this.$emit("report-updated", {
              report: this.report,
              tableId: this.currentTable?.id,
            });
            throw new Error(res.data.message);
          }
        }
        this.$emit("close");
        this.$emit("fetch");
        this.$emit("fetchStatuses");
        this.$emit("fetchViewStatus");
      } catch (err) {
        console.log(err);
        window.alert(err || "Something went wrong!", {
          type: "error",
          position: "bottom-right",
        });
        this.$emit("report-updated", {
          report: this.report,
          tableId: this.currentTable.id,
        });
        this.$emit("isCatchErr", true);
        this.$emit("isDisabled", false);
        // this.$emit("close");
        this.isLoading = false;
      } finally {
        this.isLoading = false;
        this.$emit("isLoad", false);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
:deep(.b-form-tags-list) {
  gap: 5px;
}
:deep(.align-items-baseline) {
  align-items: center !important;
  gap: 5px;
}
.report-create {
  &__footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
}
.table-column {
  margin-left: 20px;
  display: flex;
  gap: 10px;
  align-items: center;
}
.table-row {
  display: flex;
  align-items: center;
}
::v-deep .vs__dropdown-toggle {
  border-radius: 10px;
  border: 1px solid #e7edfb;
  background: #fff;
  height: 40px;
}

::v-deep ::placeholder {
  color: rgba($color: #768192, $alpha: 0.8);
  font-size: 12px;
}
.add-tag {
  ::v-deep .vs__search {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #768192;
    border-radius: 0;
  }

  ::v-deep .vs__dropdown-toggle {
    border-radius: 0;
    height: 35px;
  }
}

.table-group {
  display: flex;
  align-items: center;
}

.img-align {
  margin-right: 10px; /* Adjust this value as needed to control the spacing between the image and the label. */
}
</style>
