<template>
  <div class="view-status">
    <CModal
      :show.sync="isModalShow"
      :closeOnBackdrop="false"
      @blur="$emit('close')"
      @close="$emit('close')"
      @show="$emit('close')"
      width="50%"
      alignment="center"
      class="no-modal-footer"
    >
      <template slot="header">
        <h4>View status</h4>
        <svg
          @click="$emit('close')"
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          fill="currentColor"
          class="bi bi-x"
          viewBox="0 0 16 16"
        >
          <path
            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
          />
        </svg>
      </template>
      <CustomTable
        :fields="fields"
        :values="reports || []"
        class="auto-width"
        customPagination
        :paginationData="{
          page: paginationData.page,
          total: paginationData.total,
          page_size: paginationData.page_size,
        }"
        @update:page="(p) => $emit('update:page', p, paginationData.page_size)"
        @update:perPage="(l) => $emit('update:page', 1, l)"
        @onSearch="(s) => $emit('onSearch', s)"
      >
        <template #name="{ data }">
          {{ data.name }}
        </template>
        <template #status="{ data }">
          {{ data.status }}
        </template>

        <template #actions="{ data }">
          <div class="d-flex gap-1 flex-center">
            <CButton
              @click="onChatSelect(data)"
              color="primary"
              class="action-btn relative no-hover"
              variant="outline"
              v-c-tooltip="$t('Chat')"
            >
              <b-badge
                v-if="data.unread_messages > 0"
                pill
                variant="primary"
                class="badge-cus"
                >{{ data.unread_messages }}</b-badge
              >
              <CIcon name="cilChatBubble" />
            </CButton>
            <CButton
              @click="goToReport(data)"
              color="primary"
              class="action-btn no-hover"
              variant="outline"
              v-c-tooltip="$t('View')"
            >
              <CIcon name="cilBook" />
            </CButton>
            <CButton
              @click="$emit('onStatus', data)"
              color="primary"
              class="action-btn no-hover"
              variant="outline"
              v-c-tooltip="$t('Edit status')"
              :disabled="!data?.can_edit"
            >
              <CIcon name="cilPencil" />
            </CButton>
          </div>
        </template>
      </CustomTable>
      <!-- <TableLoader v-else /> -->
      <template slot="footer">
        {{ null }}
      </template>
    </CModal>
  </div>
</template>
<script>
import CustomTable from "@/components/CustomTable.vue";
import { ActiveUser } from "@/services/user";
import { statusesCanChangeAdmin } from "@/constants/report";
import TableLoader from "../../../components/TableLoader.vue";

export default {
  name: "ViewStatusModal",
  components: { CustomTable, TableLoader },
  props: {
    isViewStatus: Boolean,
    reports: Array,
    isLoading: Boolean,
    paginationData: Object,
  },
  data() {
    return {
      fields: [
        { key: "name", sortable: true, field: "name", label: "BI template" },
        { key: "status", sortable: true, field: "status" },
        { key: "actions" },
      ],
      isModalShow: false,
    };
  },

  computed: {
    isAdmin() {
      return this.$store.state.role.includes("bi_admin");
      // return ActiveUser.isBiAdmin();
    },
    isClient() {
      return this.$store.state.role.includes("user");
      // return ActiveUser.isUser();
    },
  },
  watch: {
    isViewStatus: {
      immediate: true,
      handler(val) {
        this.isModalShow = val;
      },
    },
    isModalShow(val) {
      if (!val) this.$emit("close");
    },
  },

  methods: {
    goToReport(data) {
      localStorage.setItem("modalState", JSON.stringify(true));
      this.$router.push(`/bi-marketplace/report/${data.id}`);
    },
    onChatSelect(data) {
      this.$emit("onChatSelect", data);
    },
    canEditStatus(report) {
      return statusesCanChangeAdmin.includes(report.status)
        ? this.isAdmin
        : this.isClient || ActiveUser.user.email === report.author.email;
    },
  },
};
</script>
<style lang="scss" scoped>
.view-status::v-deep {
  .modal-dialog.modal-sm {
    min-width: 50% !important;
    padding-left: 25%;
    translate: (-50%, 0);
  }
  .modal.show .modal-dialog {
    transform: translate(50%, 50px);
  }
}
</style>
