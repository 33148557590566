<template>
  <div class="Chat-modal">
    <CModal
      class="chat-modal-body"
      id="Chat"
      alignment="center"
      width="40%"
      :show.sync="visible"
      :closeOnBackdrop="false"
    >
      <template slot="header">
        <h4>{{ selectedChat && selectedChat.name }}</h4>
        <svg
          @click="$emit('closePopup')"
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          fill="currentColor"
          class="bi bi-x"
          viewBox="0 0 16 16"
        >
          <path
            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
          />
        </svg>
      </template>
      <div class="Chats">
        <template v-for="c in currentChatMessages">
          <div v-if="c.techical" class="event" v-html="c.message"></div>
          <div
            v-else-if="c.author && c.author.trim() === username.trim()"
            class="chatMe"
          >
            <div class="message-info">
              <span class="User">{{ c.author }}</span>
              <span class="Time">{{ c.created_at | readableDate }}</span>
            </div>
            <div class="message-text" v-html="c.message"></div>
          </div>
          <div
            v-else-if="c.author && c.author.trim() !== username.trim()"
            class="chatUser"
          >
            <div class="message-info">
              <span class="User">{{ c.author }} </span>
              <span class="Time">{{ c.created_at | readableDate }}</span>
            </div>
            <div class="message-text" v-html="c.message"></div>
          </div>
        </template>
      </div>
      <div class="Content">
        <vue-editor
          v-model="content"
          :editorToolbar="customToolbar"
          :placeholder="$t('Chat here')"
        ></vue-editor>
        <button data-cy="send-btn" class="bi-button" @click="sendMessage">
          {{ $t("Send") }}
        </button>
      </div>
    </CModal>
  </div>
</template>
<script>
import { VueEditor } from "vue2-editor";
import moment from "moment";

export default {
  name: "Chats",
  props: [
    "username",
    "isChatVisible",
    "selectedChat",
    "sendMessageToChat",
    "currentChatMessages",
    "clearInterval",
  ],
  components: {
    VueEditor,
  },
  filters: {
    readableDate(date) {
      return moment(date).local().format("HH:mm Do MMMM YYYY");
    },
  },
  data() {
    return {
      chats: [
        {
          user: "Ghost user",
          content: "<p>Aidar was here</p>",
          date: "Tuesday 13. March 2023",
          time: "23:50",
        },
      ],
      view: false,
      visible: false,
      content: "",
      weekday: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
    };
  },
  async mounted() {
    this.scrollToEnd();
  },
  watch: {
    visible(val) {
      if (val) {
        const el = document.querySelector(".Chats");
        setTimeout(() => {
          el.scrollTop = el.scrollHeight;
        }, 200);
      }
      this.visible = val;
      if (!val) {
        console.log("CLOSE POP UP !!");
        this.$emit("closePopup", false, null);
        this.$emit("fetch");
        this.clearInterval();
      }
    },
    isChatVisible(val) {
      this.visible = val;
    },
  },
  methods: {
    async sendMessage() {
      const contentWithoutTags = this.content.replace(/<[^>]*>/g, ""); // Remove HTML tags

      console.log(contentWithoutTags);
      if (contentWithoutTags.trim() !== "") {
        await this.sendMessageToChat({
          message: `<div>${this.content}</div>`,
        });
        this.content = "";
        this.scrollToEnd();
      }
    },
    scrollToEnd() {
      const list = this.$el.querySelector(".Chats");
      list.scrollTop = list.scrollHeight;
    },
  },
};
</script>
<style scoped lang="scss">
.Chat-modal::v-deep {
  .modal-dialog.modal-sm {
    left: 0px !important;
  }
  .modal-content{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
}
.event {
  align-self: center;
  text-align: center;
  background-color: #3566c1;
  color: white;
  max-width: 400px;

  :deep(p) {
    margin: 5px;
  }
}
.Chats .chatUser {
  align-self: flex-start;
  padding: 10px 15px 0 15px;
  background: #fff;
  max-width: 90%;
  min-width: 500px;

  @media (max-width: 1439px) {
    min-width: 300px;
  }
}
.Chats .chatMe {
  text-align: end;
  align-self: flex-end;
  padding: 10px 15px 0 15px;
  background: #fff;
  max-width: 90%;
  min-width: 500px;

  @media (max-width: 1439px) {
    min-width: 300px;
  }
}
.modal-body {
  min-height: 50vh;
  @media (max-width: 768px) {
    min-width: 50vw;
    .Title {
      font-size: 0.9rem;
    }

    .Chats {
      margin: 20px 15px;
      font-size: 14px;
      scroll-behavior: smooth;

      @media (max-width: 1439px) {
        font-size: 12px;
      }
    }
  }
}
.chat-modal-body::v-deep .modal-dialog {
  min-width: 50vw;
  transform: translateX(100%);
  transform: translateY(15%);
}
#Chat::v-deep {
  #quill-container {
    margin-top: 10px;
    border-radius: 8px;
    border: 0;
  }

  .ql-formats,
  .ql-formats {
    display: flex;
    gap: 10px;
  }

  .ql-formats {
    margin-right: 0px;

    &::before {
      content: none;
    }
  }

  .quillWrapper {
    width: 100%;
    max-width: 85%;
    height: 100%;
  }
  .ql-editor.ql-blank::before {
    color: #737484;
  }

  .chatMe *,
  .chatUser * {
    word-wrap: break-word;
  }

  .ql-toolbar {
    display: flex;
    gap: 0px;

    border-radius: 8px;
    border: 0;

    button {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 40px;
      height: 40px;
      background: #f5f6f8;
      border-radius: 10px;
    }
  }

  .ql-editor {
    background: #f5f6f8;
    border-radius: 10px;
  }
  .modal-title {
    font-weight: bold;
    color: #2d2d2d;
  }
}
.chat-modal-body::v-deep .modal-footer {
  display: none;
}
.Title {
  text-decoration: underline;
  color: #2d2d2d;
  font-weight: bold;
  font-size: larger;
  margin: 25px 0 0 25px;
}
@keyframes fadeinout {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.FullHeight {
  height: 75vh;
  width: 90%;
  box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  animation: fadeinout 1s linear forwards;
}
.Chats {
  height: calc(100% - 350px);
  overflow-y: auto;
  margin: 25px 5% 10px 5%;
  max-height: 400px;
  min-height: 250px;
  background-color: #f5f6f8;
  border-radius: 10px;
  padding: 10px;

  display: flex;
  flex-direction: column;
  scroll-behavior: smooth;

  gap: 12px;
  width: 90%;

  > div {
    padding: 5px;
    border-radius: 8px;
  }
}
.Content {
  width: 90%;
  margin: 10px 0 20px 5%;
  background-color: white;
  border-radius: 8px;
  bottom: 0;

  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 10px;

  &::v-deep .ql-editor {
    min-height: 50px;
    max-height: 150px;
    overflow-y: auto;
    font-size: 14px;

    @media (max-width: 1439px) {
      min-height: 40px;
      font-size: 12px;
    }
  }
}
.Send {
  width: 100px;
  height: 50px;
  border: thin solid grey;
  background-color: #3f4b62;
  color: white;
  border-radius: 8px;
  margin: 0 10px 10px 0;
  margin: 10px 0 0 0;
  align-self: flex-end;
  font-size: 14px;
  flex-shrink: 0;

  @media (max-width: 1439px) {
    width: 80px;
    height: 40px;
    font-size: 12px;
  }
}
.User {
  color: #2d2d2d;
  font-weight: bold;
}
.Time {
  color: grey;
}
.AvatarStyleText {
  display: inline-block;
  color: white;
  vertical-align: middle;
}
.ButtonWrapper {
  border-radius: 50%;
  position: fixed;
  right: 10px;
  height: 50px;
  width: 50px;
  right: 40px;
  bottom: 85px;
  cursor: pointer;
}
.ExitWrapper {
  color: white;
  background-color: salmon;
  &:hover {
    transition: 0.5s;
    transform: rotate(90deg);
  }
}
.Exit {
  width: 2rem !important;
  height: 2rem !important;
  margin: 8px;
}
.DialogWrapper {
  color: white;
  background-color: #457e8f;
}
.Dialog {
  width: 1.75rem !important;
  height: 1.75rem !important;
  margin: 10px;
}

// .Time + .User,
// .User + .Time {
//   margin-left: 50px;
// }

.message-info {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
</style>
