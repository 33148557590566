<template>
  <div class="bi-m-card" @click="onClick">
    <div
      class="bi-m-card__top"
      :style="`background-image: url(${data.image})`"
    ></div>
    <div class="bi-m-card__body">
      <div class="bi-m-card__title">{{ data.name }}</div>
      <div class="bi-m-card__text">
        {{ data.description | limitText }}
      </div>
      <div class="bi-m-card__app">
        <div class="bi-m-card__app__item" @click.stop="handleFavorite(data.id)">
          <img src="@/assets/images/logos_microsoft-power-bi.png" />
          <span>Power BI</span>
          <CButton v-if="data.is_favorite" :disabled="isLoading">
            <img
              src="@/assets/images/star-full.png"
              style="margin-left: auto"
            />
          </CButton>
          <CButton v-else :disabled="isLoading">
            <img
              src="@/assets/images/Group 212.png"
              style="margin-left: auto"
            />
          </CButton>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ReportService } from "@/services/reports";
export default {
  name: "BiMarketplaceCard",
  props: {
    data: Object,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  filters: {
    limitText(s) {
      const len = 127;
      return s && s.length > len ? s.substr(0, len) + " ..." : s;
    },
  },
  methods: {
    onClick(e) {
      if (e.target.closest(".btn") === this.$refs.btn) {
        this.isLoading = true;
        ReportService.update(
          { is_favorite: !this.data.is_favorite },
          this.data.id
        )
          .then(() => {
            this.data.is_favorite = !this.data.is_favorite;
          })
          .catch((err) => console.log(err))
          .finally(() => (this.isLoading = false));
      } else this.$emit("onCardClick", this.data.id);
    },

    handleFavorite(id) {
      this.isLoading = true;
      try {
        ReportService.postFavorite(id);
        this.data.is_favorite = !this.data.is_favorite;
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
<style lang="scss">
.bi-m-card {
  display: grid;
  width: 206px;
  border-radius: 20px;
  background: #fff;
  cursor: pointer;
  box-shadow: 2px 2px 10px 0px rgba(12, 32, 68, 0.05);
  transition: all 250ms ease-in-out;
  grid-template-rows: 120px 1fr;

  &:hover {
    transform: scale(1.06);
  }

  &__top {
    background-size: cover;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    height: 120px;
  }

  &__body {
    padding: 17px 16px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__title {
    color: #0c2044;
    font-family: Mulish;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
  }

  &__text {
    color: #0c2044;
    font-family: Mulish;
    font-size: 12px;
    line-height: normal;
    margin: 15px 0 12px;
    flex: 1;
  }

  &__app {
    &__item {
      color: #0c2044;
      font-family: Mulish;
      font-size: 12px;
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    margin-right: 20px;
    justify-content: flex-end;
    margin-bottom: 21px;
    gap: 16px;
  }
}
</style>
