<template>
  <div class="relative">
    <div class="bi-market__head">
      <div
        class="d-flex gap-3 justify-content-between align-items-center"
        style="width: 100%"
      >
        <h3 class="page-title margin-0">BI templates marketplace</h3>

        <div class="call-for-action-btns">
          <div class="d-flex align-items-center gap-3 justify-content-end">
            <div class="bi-market__top-btns">
              <div
                @click="toggleFavorites"
                style="cursor: pointer; margin-right: 20px"
                v-if="isFav"
                v-c-tooltip="$t('View every template')"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  fill="currentColor"
                  class="bi bi-star-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"
                  />
                </svg>
              </div>
              <div
                v-else
                @click="toggleFavorites"
                style="cursor: pointer; margin-right: 20px"
                v-c-tooltip="$t('View only Favorites')"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  fill="currentColor"
                  class="bi bi-star"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"
                  />
                </svg>
              </div>
            </div>
            <form class="bi-market__filters" style="width: 200px">
              <b-form-input
                style="
                  font-family: 'Arial', sans-serif;
                  border-color: #3c3c3c42 !important;
                "
                @input="searchChange"
                type="text"
                placeholder="Search for name"
                required
              ></b-form-input>
            </form>
            <div style="width: 200px">
              <v-select
                :options="tags"
                multiple
                @input="onTagChange"
                v-model="currentTags"
                placeholder="Search for tag"
                style="font-family: 'Arial', sans-serif"
              />
            </div>
          </div>
          <div class="d-flex align-items-center gap-3">
            <button class="bi-button" @click="onCreate">
              Add new BI template
            </button>
            <button
              class="bi-button"
              @click="viewStatusShow"
              style="height: 41px"
            >
              <span style="margin-right: 5px">View status</span>
              <CButton
                v-if="totalMessages > 0"
                color="primary"
                class="action-btn relative no-hover"
                variant="outline"
              >
                <b-badge
                  pill
                  variant="primary"
                  style="position: absolute; top: -3px; right: -6px"
                  >{{ totalMessages }}</b-badge
                >
                <CIcon name="cilChatBubble" />
              </CButton>
            </button>
            <button class="bi-button" @click="handleSendReq">
              Send request for custom report
            </button>
          </div>
        </div>
      </div>
    </div>

    <template>
      <template v-if="!isLoading">
        <div class="bi-market__body">
          <template>
            <BiMarketplaceCard
              @onCardClick="onSelectCard"
              @onButtonClick="onGetIt"
              v-for="r in reports"
              :key="r._id"
              :data="r"
            />
          </template>
        </div>
        <div class="custom-table__pagination">
          <b-pagination
            :value="page"
            @input="onPageChange"
            :total-rows="total"
            :per-page="page_size"
            aria-controls="my-table"
          ></b-pagination>
        </div>
      </template>
      <BiCardLoader v-else />
    </template>
    <ViewStatusModal
      v-if="isViewStatus"
      :isViewStatus="isViewStatus"
      @close="onCloseViewStatus"
      :reports="viewReports"
      :isLoading="isViewStatusLoading"
      :paginationData="viewStatusPagination"
      @onChatSelect="onChatSelect"
      @update:page="onViewStatusPage"
      @onStatus="onStatusSelect"
      @fetch="getViewStatusData"
      @onSearch="onSearchViewStatus"
    />

    <CModal
      :show.sync="isCreate"
      :closeOnBackdrop="false"
      @blur="isCreate = false"
      @close="isCreate = false"
      @show="isCreate = false"
      width="50%"
      height="95%"
      alignment="center"
    >
      <template slot="header">
        <h4>{{ !!currentReport ? "Edit report" : "Add new BI template" }}</h4>
        <svg
          @click="isCreate = false"
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          fill="currentColor"
          class="bi bi-x"
          viewBox="0 0 16 16"
        >
          <path
            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
          />
        </svg>
      </template>
      <ReportCreate
        ref="reportCreateRef"
        v-if="isCreate"
        :data="currentReport"
        @close="isCreate = false"
        @fetch="getReports"
        @fetchStatuses="getUnreadMsgCount"
        @fetchViewStatus="getViewStatusData"
        @loadingTrue="isLoading = true"
        @report-updated="handleReportUpdated"
        @isLoad="handleSetReportLoading"
        @isDisabled="handleDisabledButton"
        @isCatchErr="handleIsCatchErr"
      />
      <template slot="footer"
        ><div
          class="d-flex align-center"
          style="width: 100%; justify-content: space-between"
        >
          <div>
            <span class="text-danger"
              >Table you are selecting will be publicly available for EVERY USER
              of DataMorph</span
            >
          </div>
          <div class="d-flex gap-3">
            <b-button
              class="btn cus-button cuz-button--white"
              @click="isCreate = false"
            >
              Cancel
            </b-button>
            <b-button
              class="btn cus-button"
              @click="$refs.reportCreateRef.onSave()"
              :disabled="isDisabled || isReportLoading"
            >
              <div
                class="btn-loader__wrapper"
                :class="{ 'one-col': !isReportLoading }"
              >
                <span>Save</span>
                <CSpinner
                  v-if="isReportLoading"
                  color="white"
                  style="width: 1.5rem; height: 1.5rem"
                />
              </div>
            </b-button>
          </div></div
      ></template>
    </CModal>

    <Chat
      :isChatVisible="isChatVisible"
      :username="email"
      :selectedChat="selectedChat"
      :currentChatMessages="currentChatMessages"
      :sendMessageToChat="sendMessageToChat"
      :clearInterval="clearInterval"
      @closePopup="onCloseChat"
      @fetch="handleFetch"
    />

    <CustomReportModal
      :isCustomReport="isCustomReport"
      @close="isCustomReport = false"
    />
  </div>
</template>
<script>
import { CIcon } from "@mz/coreui-vue";
import BiMarketplaceCard from "./components/BiMarketplaceCard.vue";
import "vue-select/dist/vue-select.css";
import ReportCreate from "./components/ReportCreate.vue";
import { ReportService } from "@/services/reports";
import ViewStatusModal from "./components/ViewStatusModal.vue";
import Chat from "./components/Chat.vue";
import { ActiveUser } from "@/services/user";
import { ChatService } from "@/services/chats";
import CustomReportModal from "./components/CustomReportModal.vue";
import Loader from "../../components/Loader.vue";
import BiCardLoader from "./components/BiCardLoader.vue";
import HelpMenu from "@/components/HelpMenu.vue";
import { debounce } from "@/utils/helpers";

export default {
  name: "BiMarketplace",
  components: {
    HelpMenu,
    BiMarketplaceCard,
    CIcon,
    ReportCreate,
    Chat,
    ViewStatusModal,
    CustomReportModal,
    Loader,
    BiCardLoader,
  },
  data() {
    return {
      filterOptions: ["Name", "Description", "Tag", "Column name"],
      isCreate: false,
      isViewStatus: false,
      reports: [{ report_name: "Report", status: "status" }],
      isChatVisible: false,
      email: ActiveUser.get()?.email,
      selectedChat: null,
      currentChatMessages: [],
      interval: null,
      currentReport: null,
      isCustomReport: false,
      isLoading: false,
      isViewStatusLoading: false,
      viewReports: [],
      totalMessages: 0,
      originalReports: [],
      isFav: false,
      tags: [],
      currentTags: [],
      shownElements: [],
      isUnreadMsg: false,
      isDisabled: false,
      isReportLoading: false,
      isCatchErr: false,
      page: 1,
      page_size: 10,
      total: 0,
      filter: {},
      viewStatusPagination: { page: 1, page_size: 10, total: 0, filter: {} },
      searchChange: null,
    };
  },
  created() {
    this.getReports();
    this.getTags();
    // this.getViewStatusData();
    this.getUnreadMsgCount();
    const modalState = localStorage.getItem("modalState");
    if (modalState) {
      this.isViewStatus = JSON.parse(modalState);
    }
    localStorage.removeItem("modalState");

    this.searchChange = debounce((text) => {
      if (text?.length > 2 || (text === "" && this.filter.name?.length)) {
        this.filter.name = text;
        this.getReports();
      }
    }, 500);
  },
  methods: {
    onCloseViewStatus() {
      this.isViewStatus = false;
      this.viewStatusPagination = {
        page: 1,
        page_size: 10,
        total: 0,
        filter: {},
      };
    },
    onTagChange(tags) {
      console.log(tags);
      this.filter.tags = tags;
      this.getReports();
    },
    onSearchViewStatus(s) {
      console.log("ss", s);
      this.viewStatusPagination.filter = s;
      this.getViewStatusData();
    },
    onPageChange(p) {
      this.page = p;
      this.getReports();
    },
    onViewStatusPage(p, l) {
      this.viewStatusPagination.page = p;
      this.viewStatusPagination.page_size = l;
      this.getViewStatusData();
    },
    handleSetReportLoading(load) {
      this.isReportLoading = load;
    },
    handleDisabledButton(flag) {
      this.isDisabled = flag;
    },
    handleIsCatchErr(flag) {
      this.isCatchErr = flag;
    },
    handleReportUpdated(payload) {
      if (this.isCatchErr) {
        this.isDisabled = false;
        return;
      }
      const { report, tableId } = payload;
      if (this.currentReport !== null) {
        this.isDisabled = false;
        return;
      } else if (this.isLoading) {
        this.isDisabled = true;
        return;
      }
      const hasName = report.name && report.name.trim() !== "";
      const hasStatus = report.status;
      const hasFile = report.file;

      this.isDisabled = !(hasName && hasStatus && hasFile && tableId);
    },
    handleSendReq() {
      this.isCustomReport = true;
    },
    viewStatusShow() {
      this.isViewStatus = true;
      this.getViewStatusData();
    },
    handleFetch() {
      if (this.isUnreadMsg) {
        this.getViewStatusData();
        this.getUnreadMsgCount();
      }
    },
    disableSave() {
      const childComponent = this.$refs.reportCreateRef;

      if (childComponent) {
        return (
          !childComponent.checkUrl ||
          !childComponent.report.status ||
          !(childComponent.report.table_data || childComponent.currentTable) ||
          !childComponent.report.name ||
          childComponent.isLoading
        );
      }
    },
    toggleFavorites() {
      this.isFav = !this.isFav;
      this.filter.favorites = this.isFav;
      this.getReports();
    },
    onGetIt() {
      console.log("button");
    },
    getViewStatusData() {
      this.isViewStatusLoading = true;
      ReportService.viewStatusV2({
        page: this.viewStatusPagination.page,
        page_size: this.viewStatusPagination.page_size,
        filter: this.viewStatusPagination.filter,
      })
        .then(({ data }) => {
          this.viewReports = data.data;
          this.viewStatusPagination.total = data.total;
        })
        .catch((err) => console.log(err))
        .finally(() => (this.isViewStatusLoading = false));
    },
    getTags() {
      ReportService.getTags({})
        .then(({ data }) => {
          this.tags = data.data;
        })
        .catch((err) => console.log(err));
    },

    getUnreadMsgCount() {
      this.isViewStatusLoading = true;
      ReportService.getUnreadMsg()
        .then(({ data }) => {
          this.totalMessages = data.total_unread_messages;
        })
        .catch((err) => console.log(err))
        .finally(() => (this.isViewStatusLoading = false));
    },
    onCreate() {
      this.isCreate = true;
      this.currentReport = null;
      this.isDisabled = true;
    },
    onStatusSelect(data) {
      this.currentReport = data;
      this.isDisabled = false;
      this.isCreate = true;
    },
    onSelectCard(id) {
      this.$router.push(`/bi-marketplace/report/${id}`);
    },
    onCloseChat() {
      this.isChatVisible = false;
      // this.getViewStatusData();
    },
    getReports() {
      this.isLoading = true;
      ReportService.getAllV2({
        page: this.page,
        page_size: this.page_size,
        filter: this.filter,
      })
        .then(({ data }) => {
          this.reports = data.data;
          this.total = data.total;
        })
        .catch((err) => console.log(err))
        .finally(() => (this.isLoading = false));
    },
    onChatSelect(data) {
      this.selectedChat = data;
      this.isChatVisible = true;
      this.isUnreadMsg = data.unread_messages > 0;
      this.getChat();
      this.interval = setInterval(() => {
        this.getChat();
      }, 2000);
    },
    getChat() {
      ChatService.getAll(this.selectedChat.id)
        .then(({ data }) => {
          this.currentChatMessages = data.data;
        })
        .catch((err) => console.log(err));
    },
    sendMessageToChat(data) {
      ChatService.create(data, this.selectedChat.id)
        .then(({ data }) => {
          this.currentChatMessages = data.data;
        })
        .catch((err) => console.log(err));
    },
    clearInterval() {
      clearInterval(this.interval);
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-table__pagination {
  display: flex;
  justify-content: flex-end;
  margin-right: 2rem;
}
.margin-0 {
  margin: 0;
}
.bi-market {
  &__filters {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 400px;

    &__search {
      margin: 0;
    }
  }

  &__body {
    margin: 30px 0;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
  }

  &__top-btns {
    display: flex;
    gap: 10px;
  }

  &__bottom-btn {
    position: fixed;
    right: 30px;
    bottom: 80px;
  }
}
.call-for-action-btns {
  display: flex;
  gap: 30px;
  justify-content: space-between;
  @media only screen and (max-width: 1666px) {
    display: flex;
    flex-direction: column-reverse;
    gap: 20px;
  }
}
::v-deep ::placeholder {
  color: rgba($color: #768192, $alpha: 0.8);
  font-size: 12px;
}
</style>
