import { render, staticRenderFns } from "./BiCardLoader.vue?vue&type=template&id=73408706&scoped=true"
import script from "./BiCardLoader.vue?vue&type=script&lang=js"
export * from "./BiCardLoader.vue?vue&type=script&lang=js"
import style0 from "./BiCardLoader.vue?vue&type=style&index=0&id=73408706&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73408706",
  null
  
)

export default component.exports