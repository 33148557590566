<template>
  <div class="bi-card-loader__wrapper">
    <div class="bi-card-loader">
      <vue-skeleton-loader
        type="rect"
        rounded
        :width="206"
        :height="120"
        animation="fade"
      />
      <div class="bi-card-loader__body">
        <vue-skeleton-loader
          type="rect"
          rounded
          :width="170"
          :height="20"
          animation="fade"
        />
        <vue-skeleton-loader
          type="rect"
          rounded
          :width="170"
          :height="20"
          animation="fade"
        />
        <vue-skeleton-loader
          type="rect"
          rounded
          :width="170"
          :height="20"
          animation="fade"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "BICardLoader",
};
</script>
<style lang="scss" scoped>
.bi-card-loader {
  display: grid;
  gap: 20px;

  &__wrapper {
    width: 206px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 2px 2px 10px 0px rgba(12, 32, 68, 0.05);
  }

  &__body {
    padding: 10px 16px 20px;
    display: grid;
    gap: 20px;
  }
}
</style>
